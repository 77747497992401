function cbgr(token) {
  fetch('https://api.techstra.se/contact/recaptcha', {
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify({token}),
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
  })
  .then((response) => {
    if (response.ok) {
      return response.json()
    } else {
      console.log("unsuccessful recaptcha resp")
    }
  })
  .then((body) => {
    let el = document.getElementById("contactsubmit")
    el.disabled = false
  })
  .catch((error) => {
    console.log('recaptcha verfication failed')
  })
}

function ecbgr(d) {
  let el = document.getElementById("contactsubmit")
  el.disabled = true
}
